<template>
    <v-row>
      <v-col cols="12">
          <sale-dining-per-day-card> </sale-dining-per-day-card> 
      </v-col>
    </v-row>
  </template>
  <script>
  import SaleDiningPerDayCard from "@/components/report/SaleDiningPerDayCard";
  
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Daily Dining Sales",
    },
  
    components: {
      SaleDiningPerDayCard
    },
  
    data() {
      return {
      };
    },
  };
  </script>